import React from 'react';
import { string, object } from 'prop-types';
import classNames from 'classnames';

import css from './RatingStars.module.css';

const RatingStars = ({ className = '', reviews, listingId }) => {
  if (!reviews || !listingId) return null;

  const listingReviews = reviews[listingId] || [];
  const reviewCount = listingReviews.length;
  const averageRating =
    reviewCount > 0
      ? listingReviews.reduce((sum, review) => sum + review.attributes.rating, 0) / reviewCount
      : 0;

  if (reviewCount === 0) return null;

  const roundedRating = Math.round(averageRating * 2) / 2;
  const fullStars = Math.floor(roundedRating);
  const hasHalfStar = roundedRating % 1 !== 0;

  return (
    <div className={classNames(css.root, className)}>
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          className={classNames(css.star, {
            [css.fullStar]: index < fullStars,
            [css.halfStar]: index === fullStars && hasHalfStar,
          })}
        >
          ★
        </span>
      ))}
      <span className={css.rating}>{roundedRating.toFixed(1)}</span>
      <span className={css.reviewCount}>({reviewCount})</span>
    </div>
  );
};

RatingStars.propTypes = {
  className: string,
  reviews: object.isRequired,
  listingId: string.isRequired,
};

export default RatingStars;
